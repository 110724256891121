import styled from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';
import { Box } from '../Box/Box.js';
import { Button } from '../Button/Button.js';
import { Time } from '../Time/Time.js';
import FileTextIcon from '../Icon/icons/FileText.js';
import ImageIcon from '../Icon/icons/Image.js';

// TODO: Refactor nested classes to use styled-components
const StyledUploadFileListItem = styled(Box).withConfig({
  componentId: "sg1670__sc-js7exd-0"
})([".upload-file-list-item-file{align-items:center;display:flex;flex:1 1 auto;min-width:1px;text-decoration:none;}"]);
const FileListItemInfo = styled.div.withConfig({
  componentId: "sg1670__sc-js7exd-1"
})(["display:flex;flex-wrap:wrap;"]);
const FileInfo = styled.div.withConfig({
  componentId: "sg1670__sc-js7exd-2"
})(["align-items:center;display:flex;flex:1 1 auto;min-width:1px;text-decoration:none;"]);
const FileButton = styled(Button).withConfig({
  componentId: "sg1670__sc-js7exd-3"
})(["flex:0 0 auto;max-width:100%;"]);
const FileListItemTime = styled(Time).withConfig({
  componentId: "sg1670__sc-js7exd-4"
})(["margin-right:", ";"], tokens.space.xs);
const StyledFileTextIcon = styled(FileTextIcon).withConfig({
  componentId: "sg1670__sc-js7exd-5"
})(["flex:0 0 auto;margin-right:", ";"], tokens.space.xs);
const StyledImageIcon = styled(ImageIcon).withConfig({
  componentId: "sg1670__sc-js7exd-6"
})(["flex:0 0 auto;margin-right:", ";"], tokens.space.xs);

export { FileButton, FileInfo, FileListItemInfo, FileListItemTime, StyledFileTextIcon, StyledImageIcon, StyledUploadFileListItem };
