import React from 'react'

import {
  Text,
  Time,
  UploadFileList,
  UploadFileListItem
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const UploadFileListExample3 = () => (
  <UploadFileList noMargin>
    <UploadFileListItem
      date={new Date()}
      component={<LinkMock to="Tiedosto päivämäärällä.tif" />}
      name="Tiedosto päivämäärällä.tif"
      description="Kuvausteksti"
    />
    <UploadFileListItem
      date={
        <Text>
          Lisätty jälkikäteen{' '}
          <Time
            date={new Date()}
            withYear
          />
        </Text>
      }
      component={<LinkMock to="Tiedosto tekstillä ja päivämäärällä.doc" />}
      name="Tiedosto tekstillä ja päivämäärällä.doc"
      description="Kuvausteksti"
    />
  </UploadFileList>
)

export { UploadFileListExample3 }
