import React from 'react'

import {
  Paragraph,
  UploadFileList,
  UploadFileListItem
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { Link } from '../../components/Link'
import { LinkMock } from '../../components/LinkMock'
import { Playground } from '../../components/Playground'
import { Section } from '../../components/Section'

import { UploadFileListExample1 } from '../../examples/react/UploadFileListExample1'
import { UploadFileListExample2 } from '../../examples/react/UploadFileListExample2'
import { UploadFileListExample3 } from '../../examples/react/UploadFileListExample3'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="UploadFileList"
    components={[
      { component: UploadFileList },
      { component: UploadFileListItem }
    ]}
  >
    <Section title="Vaalea taustaväri">
      <Paragraph>
        Vaaleaa taustaväriä (<Code>light</Code>) käytetään listaamaan ladattavia
        tiedostoja, joiden lataus on kesken tai joita voidaan vielä muokata
        esim. lisäämällä tiedostolle kuvausteksti (esim.{' '}
        <Code>
          <Link page="Dropzone" />
        </Code>
        -komponentissa).
      </Paragraph>
      <Playground example={UploadFileListExample1} />
    </Section>
    <Section title="Valkoinen taustaväri">
      <Paragraph>
        Valkoista taustaväriä (<Code>white</Code>) käytetään listaamaan ns. read
        only -tilaisia tiedostoja, eli tiedostoja jotka ovat osa sisältöä tai
        jotka käyttäjä on ladannut aiemmin (esim. Lisää-toiminnolla). Aiemmin
        ladatuille tiedostoille voidaan näyttää latauspäivämäärä (
        <Code>date</Code>).
      </Paragraph>
      <Playground>
        <UploadFileListItem
          component={<LinkMock to="Tiedosto päivämäärällä.doc" />}
          date={new Date()}
          description="Consectetuer adipiscing elit"
          name="Tiedosto päivämäärällä.doc"
        />
      </Playground>
      <Paragraph>
        Tarvittaessa myös aiemmin ladatuille tiedostoille voidaan tarjota
        toimintopainike, jolla käyttäjän on mahdollista esim. poistaa tiedosto.
        Tällöin Itemin taustaväriksi pitää erikseen määritellä{' '}
        <Code>color=white</Code>, joka yliajaa <Code>actions</Code>-propin
        automaattisen taustavärin vaihdon.
      </Paragraph>
      <Playground example={UploadFileListExample2} />
    </Section>
    <Section title="Päivämäärä">
      <Paragraph>
        Päivämäärän voi määritellä <Code>Date</Code>-objektina tai lisätä oman
        muotoilun. Tällöin päivämärän esittämiseen tulee käyttää{' '}
        <Code>
          <Link page="Time" />
        </Code>
        -komponenttia.
      </Paragraph>
      <Playground example={UploadFileListExample3} />
    </Section>
  </Content>
)

export default Page
