import React, { useState } from 'react'

import {
  Button,
  TrashIcon,
  UploadFileList,
  UploadFileListItem,
  useInterval,
  XIcon
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const CancelButton = (
  <Button
    color="danger"
    onClick={() => alert('Click!')}
    iconLeft={<XIcon />}
  >
    Keskeytä
  </Button>
)

const RemoveButton = (
  <Button
    color="danger"
    onClick={() => alert('Click!')}
    iconLeft={<TrashIcon />}
  >
    Poista
  </Button>
)

const AnimatingUploadFileListItem = () => {
  const [progress, setProgress] = useState(0)
  const progressAnimation = (currentValue: number) => {
    const newValue = currentValue + Math.random() / 4
    return newValue > 1 ? 0 : newValue
  }

  useInterval(() => {
    setProgress(progressAnimation(progress))
  }, 1000)

  return (
    <UploadFileListItem
      actions={CancelButton}
      name="Tiedostoa ladataan.pdf"
      progress={progress}
    />
  )
}

const UploadFileListExample1 = () => {
  const [description, setDescription] = useState('')

  return (
    <UploadFileList noMargin>
      <AnimatingUploadFileListItem />
      <UploadFileListItem
        actions={CancelButton}
        name="Tiedosto puoliksi ladattu.jpg"
        progress={0.5}
      />
      <UploadFileListItem
        actions={CancelButton}
        name="Tiedoston latauksen kesto ei tiedossa.pdf"
      />
      <UploadFileListItem
        actions={RemoveButton}
        component={<LinkMock to="Tiedosto ladattu.tif" />}
        name="Tiedosto ladattu.tif"
        progress={1}
      />
      <UploadFileListItem
        actions={RemoveButton}
        component={<LinkMock to="Tiedosto linkillä ja kuvauksella.doc" />}
        description="Lorem ipsum dolor sit amet"
        name="Tiedosto linkillä ja kuvauksella.doc"
      />
      <UploadFileListItem
        actions={RemoveButton}
        component={<LinkMock to="Tiedosto muokattavalla kuvauksella.doc" />}
        description={description}
        editable
        name="Tiedosto muokattavalla kuvauksella.doc"
        onDescriptionChange={event => setDescription(event.target.value)}
      />
      <UploadFileListItem
        actions={RemoveButton}
        description="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere interdum sem. Quisque ligula eros ullamcorper quis, lacinia quis facilisis sed sapien."
        name="Tiedosto painikkeella, erittäin pitkällä nimellä ja kuvauksella.png"
        onClick={() => alert('Click!')}
      />
    </UploadFileList>
  )
}

export { UploadFileListExample1 }
