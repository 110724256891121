import React from 'react'

import {
  Button,
  TrashIcon,
  UploadFileList,
  UploadFileListItem
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const RemoveButton = (
  <Button
    color="danger"
    onClick={() => alert('Click!')}
    iconLeft={<TrashIcon />}
  >
    Poista
  </Button>
)

const UploadFileListExample2 = () => (
  <UploadFileList noMargin>
    <UploadFileListItem
      date={new Date()}
      actions={RemoveButton}
      component={<LinkMock to="Tiedosto toiminnolla ja päivämäärällä.tif" />}
      name="Tiedosto toiminnolla ja päivämäärällä.tif"
      color="white"
      description=""
    />
  </UploadFileList>
)

export { UploadFileListExample2 }
